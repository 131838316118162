import { Route, Routes, Navigate } from "react-router-dom"
import Dashboard from "./pages/Dashboard"
import PairPage from "./pages/Dashboard/Pair"
import Swap from "./pages/Swap"
import Vest from "./pages/Vest"
import Affiliate from "./pages/Dashboard/gen_aff"
import UnstakeStatus from "./pages/Dashboard/UnstakeStatus"
import TransactionHistory from "pages/Dashboard/TransactionHistory"
import PtoP from "pages/Dashboard/PtoP"
//import IbcBridge from "pages/Dashboard/Wormhole_connect"
import EscrowInfo from "pages/Dashboard/EscrowInfo"
import VestingInfo from "pages/Dashboard/VestingInfo"
import CreditsInfo from "pages/Dashboard/credits"
import VirtualMining from "pages/Dashboard/VirtualMining"
import MemeCalculator from "pages/Dashboard/MemeCalc"

export default () => (
  <Routes>
    <Route index element={<Dashboard />} />
    <Route path="/swap" element={<Swap />} />
    <Route path="/vest" element={<Vest />} />
    <Route path="/ptop" element={<PtoP />} />
    <Route path="/transactionhistory" element={<TransactionHistory />} />
    <Route path="/unstakestatus" element={<UnstakeStatus />} />
    <Route path="/affiliate" element={<Affiliate />} />
    <Route path="/escrowinfo" element={<EscrowInfo />} />
    <Route path="/vestinginfo" element={<VestingInfo />} />
    <Route path="/credits" element={<CreditsInfo />} />
    <Route path="/virtualmining" element={<VirtualMining />} />
    {/*<Route path="/ibcbridge" element={<IbcBridge/>} />*/}
    <Route path="/pairs/:address" element={<PairPage />} />
    <Route path="/memecoinmadnesscalc" element={<MemeCalculator />} />
    <Route path="/migration" element={<Swap />} />
    <Route path="*" element={<Navigate to="/swap" replace />} />
  </Routes>
)
